.header {
  z-index: 1;
  position: fixed;
  height: 56px;
  background-color: white;
  width: 100%;
}
.header-container {
  max-width: 960px;
  margin: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
}
.header-container .logo {
  height: 54px;
}

.header-container .menu-icon {
    display: none;
    height: 40px;
    margin: 0 8px;
}

.header-container .section-left {
  display: flex;
  align-items: center;
}

.header-container .section-left .round-button {
  border-radius: 22px;
  background-color: transparent;
  border: 1px solid gray;
  width: 75px;
  height: 34px;
}
.header-container .section-left .header-link {
  /* border-radius: 22px; */
  background-color: transparent;
  border: 1px solid transparent;
  width: 75px;
  height: 34px;
}

.header-container .section-left .header-link,
.header-container .section-left button {
  margin: 0 12px;
  text-decoration: none;
  font-size: 16px;
  height: 100%;
}
.header-container .section-left a {
  color: black;
  text-align: center;
}
@media only screen and (max-width: 768px) {
  .header-container .menu-icon {
    display: block;
  }
  .header-link {
    display: none;
  }

}
