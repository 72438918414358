.upload-icon {
  border-radius: 50%;
  background-color: #f9f9f9;
  padding: 40px;
  width: min-content;
  margin: 0 auto;
}

.upload-icon img {
  height: 50px;
  width: 50px;
  filter: opacity(0.3);
}

.label {
  margin-top: 1em;
}

img.dragging {
  transform: rotateX(55deg);
}

.files-preview li {
  list-style: none;
  /* position: relative; */
}
.video-wrapper {
  position: relative;
  margin-inline: auto;
  width: -webkit-fill-available;
}

.files-preview li .remove-file {
  position: absolute;
  right: 0;
  border: 1px solid transparent;

  padding: 0.5em;
}

.files-preview .progress-bar {
  position: absolute;
  top: 0;
  width: 100%;
  height: 6px;
  background-color: lightgrey;
}

.files-preview .progress-bar .progress {
  background-color: red;
  height: 100%;
}

.progress-bar + .remove-file {
  top:6px;
}
