textarea{
    font-family: sans-serif;
    width: -webkit-fill-available;
}
.fill{
    display: flex;
    justify-content: center;
    height: calc(100vh - 56px);
}
.spinner{

    -webkit-animation:spin 2s linear infinite;
    -moz-animation:spin 2s linear infinite;
    animation:spin 2s linear infinite;
}
@-moz-keyframes spin { 
    100% { -moz-transform: rotate(360deg); } 
}
@-webkit-keyframes spin { 
    100% { -webkit-transform: rotate(360deg); } 
}
@keyframes spin { 
    100% { 
        -webkit-transform: rotate(360deg); 
        transform:rotate(360deg); 
    } 
}

.button-spinner:has(.spinner){
    display: flex !important;
    gap:0.5em;
    cursor:wait !important;
}
