.videos-table {
    width: 100%;
    border-collapse: collapse;
}

.videos-table tr {
    border-bottom: 5px solid transparent;
}

.videos-table th:not(:nth-last-child(1)),
.videos-table td:not(:nth-last-child(1)) {
    /* text-align: center; */
    text-align: start;
    padding-inline: 0.5em;
}

.videos-table th:nth-child(1),
.videos-table td:nth-child(1),
.videos-table td:nth-last-child(1) {
    text-align: center;

}

.videos-table .edit-col {
    display: flex;
    gap: 0.5em;
    justify-content: center;
    align-items: center;
}

.videos-table tr:nth-of-type(4n -1),
.videos-table tr:nth-of-type(4n) {
    background: rgba(100, 200, 255, 0.1);
}

.videos-table tr>*:nth-child(1) {
    width: 60px;
}

.videos-table tr>*:nth-child(2) {
    width: 100px;
}

.videos-table tr>*:nth-child(3) {
    width: 100px;
}

.videos-table tr>*:nth-child(4) {
    width: 100px;
}

.videos-table tr>*:nth-child(5) {
    width: 110px;
}

.videos-table tr>*:nth-child(6) {
    width: 70px;
}

.videos-table tr>*:nth-child(7) {
    width: 50px;
}

.videos-table tr>*:nth-child(8) {
    width: 50px;
}
.videos-table tr>*:nth-child(9) {
    width: 200px;
}

.videos-table .input-field-inset input{
    width: 0;
}
.videos-table textarea{
    width: 100px;
}

.pagination {
    margin-top: 0.5em;
    display: flex;
    justify-content: center;
    align-items: center;
}
.pagination-numbers{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 0.5em; 
}

.pagination>:not(:last-child) {
    margin-left: 0.5em
}

.pagination .pagination-count {
    font-weight: 500
}

.pagination .pagination-navigation {
    display: flex
}

.pagination .pagination-navigation>:not(:last-child) {
    margin-right: .5em
}

.pagination .pagination-button {
    background: none;
    border: none
}

.pageination-button:not(.flat) {
    align-items: center;
    background-color: var(--color-primary);
    border: 1px solid var(--color-primary-darker);
    border-radius: 6px;
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, .15);
    color: var(--color-white);
    cursor: pointer;
    display: flex;
    font-family: inherit;
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    padding: 4px 8px;
}

.pageination-page:not(.flat) {
    align-items: center;
    background-color: var(--color-primary);
    border: 1px solid var(--color-primary-darker);
    border-radius: 6px;
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, .15);
    color: var(--color-white);
    cursor: pointer;
    display: flex;
    font-family: inherit;
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    padding: 4px 8px;
}

.current-page {
    background-color: var(--color-primary-darkest) !important;
    opacity: 1 !important;
}

.videos-table .input-field-container {
    margin-bottom: unset !important;
    /* align-items: center; */
}

.videos-table .copy-text-action {
    cursor: pointer;
    position: relative;
}

.videos-table .copy-text-notification {
    position: absolute;
    font-size: 12px;
    right: 20px;
    top: 20px;
}

.videos-table .block-snippet {
    background-color: white;
}

@media (max-width:800px) {
    .videos-table th {
        display: none;
    }
    .videos-table tr> td {
        width: auto !important;
    }
    

    .videos-table tr:not(.preview):not(tfoot tr) td {
        display: grid;
        grid-gap: 1rem;
        grid-template-columns: 50px auto;
        padding: 0.25rem 1rem;
        text-align: start;
    }

    .videos-table td:first-child {
        padding-top: 0.5rem;
    }

    .videos-table td:last-child {
        padding-bottom: 0.5rem;
    }

    /* .videos-table tr.preview td{
        display: block;
    } */

    .videos-table tr:not(.preview):not(tfoot tr) {

        & td:nth-of-type(1)::before {
            content: 'מספר: ';
            font-weight: bold;
        }

        & td:nth-of-type(2)::before {
            content: 'שם: ';
            font-weight: bold;
        }

        & td:nth-of-type(3)::before {
            content: 'כותרת: ';
            font-weight: bold;
        }

        & td:nth-of-type(4)::before {
            content: 'תיאור: ';
            font-weight: bold;
        }

        & td:nth-of-type(5)::before {
            content: 'תאריך העלאה: ';
            font-weight: bold;
        }

        & td:nth-of-type(6)::before {
            content: 'קישור: ';
            font-weight: bold;
        }

        & td:nth-of-type(7)::before {
            content: 'סטטוס: ';
            font-weight: bold;
        }
        & td:nth-of-type(8)::before {
            content: 'מס\' פוסט: ';
            font-weight: bold;
        }

        & td:nth-of-type(9) {
            display: block;
        }
    }

}