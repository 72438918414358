@import url(https://fonts.googleapis.com/css2?family=Rubik:wght@400;500;600;700&display=swap);@import url(https://fonts.googleapis.com/css2?family=Source+Code+Pro:wght@400;500&display=swap);.phone-display {
    align-items: flex-start;
    display: flex
}

.phone-display>:not(:last-child) {
    margin-right: 5px
}

.phone-input {
    align-items: center;
    border: 1px solid var(--color-border);
    border-radius: 6px;
    display: flex;
    overflow: hidden;
    width: 100%
}

.phone-input__country-select {
    display: inline-flex;
    flex-direction: column;
    font-weight: 400
}

.phone-input__country-select__current-value {
    align-items: center;
    cursor: pointer;
    display: inline-flex;
    padding-left: 12px
}

.phone-input__country-select__current-value>:not(:last-child) {
    margin-right: 8px
}

.phone-input__country-select__current-value .PhoneInputCountryIcon img {
    box-shadow: 0 0 3px var(--color-shadow);
    height: 14px
}

.phone-input__country-select__current-value .country-calling-code {
    color: var(--color-black)
}

.phone-input__country-select__popup {
    background-color: var(--color-white);
    border-radius: 6px;
    box-shadow: 0 0 4px var(--color-shadow);
    display: none;
    overflow-y: auto;
    position: relative;
    position: fixed
}

.phone-input__country-select__popup__option {
    align-items: center;
    cursor: pointer;
    display: flex;
    font-weight: 400;
    padding: 10px 12px
}

.phone-input__country-select__popup__option>:not(:last-child) {
    margin-right: 12px
}

.phone-input__country-select__popup__option .PhoneInputCountryIcon {
    font-size: 14px
}

.phone-input__country-select__popup__option .PhoneInputCountryIcon img {
    box-shadow: 0 0 4px var(--color-shadow);
    height: 1em
}

.phone-input__country-select__popup__option .country-calling-code {
    color: var(--color-secondary-text)
}

.phone-input__country-select__popup__option.selected {
    background-color: var(--color-window-bg);
    color: var(--color-link);
    pointer-events: none
}

.phone-input__country-select__popup__option.selected .country-calling-code {
    color: var(--color-secondary-text)
}

.phone-input__country-select__popup.popup-active {
    display: block;
    z-index: var(--z-index-inline-popup)
}

.phone-input input.PhoneInputInput {
    border: none;
    display: block;
    flex: 1 1 auto;
    font-family: inherit;
    outline: none;
    overflow-x: hidden;
    padding: 8px 12px
}

.phone-input.PhoneInput--focus {
    border-color: var(--color-primary);
    box-shadow: 0 0 0 2px var(--color-primary-opacity-40)
}

.phone-input.phone-input-error {
    border-color: var(--color-error);
    box-shadow: 0 0 0 2px var(--color-error-shadow)
}

.input-field-container {
    display: flex;
    flex-flow: column;
    margin-bottom: 24px
}

.input-label {
    color: var(--color-secondary-text);
    font-weight: 500;
    margin-bottom: 12px
}

.input-label-required {
    color: var(--color-error);
    margin: 0 4px
}

.input-field-inset {
    align-items: center;
    border: 1px solid #e0e0e0;
    border-radius: 6px;
    display: flex;
    outline: none
}

.input-field {
    background-color: #fafafa;
    border: none;
    border-radius: 6px;
    display: block;
    flex: 1 1;
    font-family: inherit;
    outline: none;
    padding: 8px 12px
}

.input-field:focus {
    background-color: #fff
}

.input-field-inset-focused,.input-field-inset:active {
    border-color: var(--color-primary);
    box-shadow: 0 0 0 2px var(--color-primary-opacity-40)
}

.input-field-inset-error-state,.input-field-inset-error-state:active,.input-field-inset-error-state:focus {
    border-color: var(--color-error);
    box-shadow: 0 0 0 2px var(--color-error-shadow)
}

.input-field-suffix .icon {
    cursor: pointer;
    padding: 8px 12px 8px 0
}

.input-field-error {
    align-items: center;
    display: flex;
    margin-top: 8pt
}

.input-field-error-icon {
    height: 16px;
    margin-bottom: 2px;
    margin-right: 8px;
    width: 16px
}

.input-field-error-text {
    line-height: normal!important
}

.user-detail-form .input-field-container:not(:last-of-type) {
    margin-bottom: 24px
}

.user-detail-form__header {
    font-size: 24px
}

.user-detail-form__actions {
    display: flex;
    justify-content: flex-end
}

.user-detail-form__actions>:not(:last-child) {
    margin-left: 24px
}

.user-detail-form__actions button {
    font-weight: 400;
    justify-content: center;
    padding: 10px 16px
}

.user-detail-form p {
    font-size: 14px;
    line-height: 24px
}

.user-detail-form p span {
    color: #ed344e;
    font-weight: 700
}

.phone-input {
    margin-bottom: 8px;
    margin-top: 12px
}

.user-delete-input-container {
    margin-top: 8px
}

.users-list-table-container {
    display: block;
    max-width: 100%;
    /* overflow-x: auto; */
    overflow-x: visible;
    padding: 24px 34px;
    width: 100%
}

.users-list-table {
    border-collapse: collapse;
    border-spacing: 0;
    max-width: 100%;
    width: 100%
}

.users-list-table thead {
    color: var(--color-secondary-text);
    font-size: 12px;
    line-height: 14px;
    text-transform: uppercase
}

.users-list-table thead tr {
    border-bottom: 1px solid var(--color-border)
}

.users-list-table thead tr th {
    font-weight: 500;
    padding: 0 1em 24px 0;
    text-align: right;
    width: 33%
}

.users-list-table tbody tr {
    border-bottom: 1px solid var(--color-border)
}

.users-list-table tbody tr td {
    max-width: 50%;
    padding: 24px 1em 24px 0;
    width: 33%
}

.users-list-table tbody tr.empty-row td {
    padding: 12px 0
}

.user-row .user-info {
    color: var(--color-secondary-text);
    display: flex;
    flex-direction: column;
    padding-right: 16px
}

.user-row .user-info div {
    max-width: 25ch;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap
}

.user-row .user-info div:not(:last-child) {
    margin-bottom: 4px
}

.user-row .user-info .main {
    color: var(--color-black);
    cursor: pointer;
    font-weight: 500
}

.user-row .user-info .main:hover {
    color: var(--color-link)
}

.user-row .user-date {
    min-width: 110px;
    white-space: nowrap
}

.user-row.placeholder td div {
    -webkit-animation: blinker 2s linear infinite;
    animation: blinker 2s linear infinite;
    background-color: var(--color-loader-placeholder-bg);
    min-height: 1em;
    opacity: .4;
    width: 100%
}

@-webkit-keyframes blinker {
    50% {
        opacity: .1
    }
}

@keyframes blinker {
    50% {
        opacity: .1
    }
}

.user-row .user-row-select-button {
    align-items: center;
    background-color: #fff;
    border: 1px solid var(--color-border);
    border-radius: 4px;
    display: inline-flex;
    height: 25px;
    justify-content: center;
    transition: .3s;
    width: 25px
}

.user-row .user-row-select-button img {
    transition: -webkit-transform .3s;
    transition: transform .3s;
    transition: transform .3s,-webkit-transform .3s;
    width: 12px;
}

.user-row .user-row-select-button .img-hover {
    display: none;
}

.user-row .user-row-select-menu {
    position: relative;
    cursor: pointer;
}

.user-row .user-row-select-menu .user-row-select-popup {
    display: none;
    padding: 20px 0 0;
    position: absolute;
    right: 0;
    top: 50%;
    z-index: var(--z-index-inline-popup)
}

.user-row .user-row-select-menu .user-row-select-popup .panel {
    min-width: 180px;
    padding: 8px
}

.user-row .user-row-select-menu .user-row-select-popup-item {
    align-items: center;
    border-radius: 4px;
    display: inline-flex;
    font-weight: 400;
    height: 28px;
    padding: 8px;
    transition: .3s;
    width: 100%
}

.user-row .user-row-select-menu .user-row-select-popup-item span {
    white-space: nowrap
}

.user-row .user-row-select-menu .user-row-select-popup-item img {
    width: 1em
}

.user-row .user-row-select-menu .user-row-select-popup-item .img-hover {
    display: none
}

.user-row .user-row-select-menu .user-row-select-popup-item.delete {
    color: var(--color-button-error-border)
}

.user-row .user-row-select-menu .user-row-select-popup-item:hover {
    background-color: var(--color-popup-item-hover)
}

.user-row .user-row-select-menu .user-row-select-popup-item:hover .img-hover {
    display: initial
}

.user-row .user-row-select-menu .user-row-select-popup-item:hover .img-normal {
    display: none
}

.user-row .user-row-select-menu .user-row-select-popup-item:hover.delete {
    background-color: var(--color-popup-item-delete-hover)
}

.user-row .user-row-select-menu:hover .user-row-select-popup {
    display: block
}

.user-row .user-row-select-menu:hover .user-row-select-button {
    border: 1px solid var(--color-primary);
    box-shadow: 0 0 5px var(--color-primary)
}

.user-row .user-row-select-menu:hover .user-row-select-button img {
    display: none
}

.user-row .user-row-select-menu:hover .user-row-select-button .img-hover {
    display: initial;
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg)
}

.user-row:last-of-type .user-row-select-popup {
    padding: 0 40px;
    top: -50%
}

.user-list-footer {
    display: flex;
    justify-content: flex-end;
    left: 0;
    padding-top: 24px;
    position: -webkit-sticky;
    position: sticky
}

.users-list-pagination {
    display: flex
}

.users-list-pagination>:not(:last-child) {
    margin-right: 1em
}

.users-list-pagination .users-list-pagination-count {
    font-weight: 500
}

.users-list-pagination .users-list-pagination-navigation {
    display: flex
}

.users-list-pagination .users-list-pagination-navigation>:not(:last-child) {
    margin-right: .5em
}

.users-list-pagination .users-list-pagination-button {
    background: none;
    border: none
}

.pill {
    align-items: center;
    border-radius: 20px;
    display: flex;
    padding: 4px 8px;
    white-space: nowrap;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content
}

.pill>:not(:last-child) {
    margin-right: 4px
}

.pill.passwordless {
    background-color: var(--color-passwordless-bg);
    color: var(--color-passwordless-text)
}

.pill.emailpassword {
    background-color: var(--color-emailpassword-bg);
    color: var(--color-emailpassword-text)
}

.pill.thirdparty {
    background-color: var(--color-custom-provider-bg);
    color: var(--color-custom-provider-text);
    max-width: 25ch;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap
}

.pill.thirdparty .thirdparty-name {
    max-width: 10ch;
    overflow: inherit;
    text-overflow: inherit
}

.pill.thirdparty.google {
    background-color: var(--color-google-bg);
    color: var(--color-google-text)
}

.pill.thirdparty.google span {
    text-transform: capitalize
}

.pill.thirdparty.apple {
    background-color: var(--color-apple-bg);
    color: var(--color-apple-text)
}

.pill.thirdparty.apple span {
    text-transform: capitalize
}

.pill.thirdparty.github {
    background-color: var(--color-github-bg);
    color: var(--color-github-text)
}

.pill.thirdparty.github span {
    text-transform: capitalize
}

.pill.thirdparty.facebook {
    background-color: var(--color-facebook-bg);
    color: var(--color-facebook-text)
}

.pill.thirdparty.facebook span {
    text-transform: capitalize
}

.footer {
    bottom: 0;
    display: flex;
    min-height: 62px;
    padding: 20px 32px;
    width: 100%
}

.footer .logo {
    height: 23px;
    width: 151px
}

.footer.alignment-right {
    justify-content: flex-end
}

.footer.alignment-center {
    justify-content: center
}

.footer.vertical-center {
    align-items: center
}

.footer.vertical-bottom {
    align-self: flex-end
}

.footer.color-dark {
    background-color: var(--color-black)
}

.footer.color-dark .logo {
    width: 148px
}

.footer.size-large {
    padding: 40px 32px
}

.no-users {
    align-items: center;
    display: flex;
    flex-flow: column;
    padding: 60px 24px
}

.no-users-image {
    height: 43px;
    margin-bottom: 24px;
    width: 63px
}

.no-users-title {
    color: var(--color-black);
    font-size: 18px;
    font-weight: 500;
    letter-spacing: .18px;
    line-height: 22px;
    margin-bottom: 8px;
    text-align: center
}

.no-users .no-users-subtitle {
    color: var(--color-secondary-text);
    line-height: 1.5em;
    max-width: 54ch;
    padding: 4px 0;
    text-align: center
}

@media only screen and (min-width: 992px) {
    .no-users {
        padding:60px
    }
}

.search {
    margin-bottom: 20px
}

.search #search-btn {
    background: var(--color-white);
    border: 1px solid var(--color-black);
    border-radius: 6px;
    cursor: pointer;
    display: inline-block;
    font-family: inherit;
    font-size: 14px;
    font-weight: 500;
    height: 35px;
    line-height: 17px;
    margin-left: 10px;
    padding: 6px 8px;
    z-index: 1
}

@media screen and (max-width: 768px) {
    .search #search-btn {
        margin-left:0;
        margin-top: 10px;
        width: 100%
    }
}

.search__input_wrapper {
    align-items: center;
    background: #fff;
    border: 1px solid #e5e5e5;
    border-radius: 6px;
    display: inline-flex;
    height: 40px;
    padding: 9px 16px;
    width: 340px
}

@media screen and (max-width: 768px) {
    .search__input_wrapper {
        width:100%
    }
}

.search__input_wrapper img {
    aspect-ratio: 1;
    height: 14px
}

.search__input_wrapper img:first-child {
    margin-bottom: 3px;
    margin-right: 10px
}

.search__input_wrapper img:last-child {
    cursor: pointer;
    height: 22px
}

.search__input_wrapper.active {
    border: 1px solid #f93;
    outline: 2px solid rgba(255,153,51,.4)
}

.search__input_wrapper input {
    border: none;
    flex-grow: 1;
    height: 20px
}

.search__input_wrapper input:active,.search__input_wrapper input:focus,.search__input_wrapper input:focus-visible,.search__input_wrapper input:focus-within {
    outline: none
}

.search__input_wrapper input::-webkit-input-placeholder {
    color: rgba(34,34,34,.7);
    font-size: 16px;
    font-weight: 400;
    line-height: 171%;
    padding-bottom: 0
}

.search__input_wrapper input::placeholder {
    color: rgba(34,34,34,.7);
    font-size: 16px;
    font-weight: 400;
    line-height: 171%;
    padding-bottom: 0
}

.searchTag {
    align-items: center;
    display: inline-flex;
    margin: 12px 8px 12px 0
}

.searchTag__value {
    background: #fafafa;
    border: 1px solid #ddd;
    border-radius: 4px;
    color: #000;
    font-size: 14px;
    font-weight: 400;
    margin-left: 4px;
    padding: 6px
}

.searchTag__value img {
    cursor: pointer;
    height: 8px;
    margin-left: 10px
}

.tag_dropdown {
    position: relative
}

.tag_dropdown__selector {
    align-items: center;
    background: #eee;
    border: 1px solid #e2e2e2;
    border-radius: 4px;
    color: #000;
    display: flex;
    font-size: 14px;
    font-weight: 500;
    padding: 6px
}

.tag_dropdown__selector img {
    margin-left: 10px
}

.tag_dropdown__selector.active {
    background: #fff2e1;
    border: 1px solid #ebdfcf;
    border-radius: 4px
}

.tag_dropdown__menu {
    background: #fff;
    border-radius: 6px;
    box-shadow: 0 0 8px rgba(0,0,0,.16);
    min-width: 180px;
    position: absolute;
    top: calc(2em + 8px);
    z-index: 999
}

.tag_dropdown__menu ul {
    list-style-type: none
}

.tag_dropdown__menu ul li {
    color: #6e6a65;
    display: flex;
    font-size: 14px;
    font-weight: 400;
    justify-content: space-between;
    margin: 7px;
    padding: 10px
}

.tag_dropdown__menu ul li:hover {
    background: #f0f0f0
}

.tag_dropdown__menu ul li span {
    align-items: baseline;
    display: flex
}

.tag_dropdown__menu ul li span img {
    height: 15px;
    margin-right: 11px
}

.tenant-pill {
    background: rgba(147,53,228,.1);
    border: 1px solid #9335e4;
    border-radius: 30px;
    color: #9335e4;
    font-size: 14px;
    max-width: -webkit-fit-content;
    max-width: -moz-fit-content;
    max-width: fit-content;
    padding: 5px 13px
}

.tenant-list-container {
    -webkit-column-gap: 16px;
    column-gap: 16px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    row-gap: 16px
}

.user-detail-page-loader {
    min-height: 80vh
}

.full-screen-loading-overlay,.user-detail-page-loader {
    align-items: center;
    display: flex;
    justify-content: center
}

.full-screen-loading-overlay {
    background-color: rgba(0,0,0,.4);
    bottom: 0;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 1
}

.loader-container {
    background-color: var(--color-window-bg);
    border-radius: 50%;
    display: flex;
    padding: 2px
}

.loader {
    -webkit-animation: spin 2s linear infinite;
    animation: spin 2s linear infinite;
    border: 16px solid #f3f3f3;
    border-radius: 50%;
    border-top-color: #f93;
    height: 60px;
    width: 60px
}

@keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg)
    }

    to {
        -webkit-transform: rotate(1turn);
        transform: rotate(1turn)
    }
}

@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0deg)
    }

    to {
        -webkit-transform: rotate(1turn)
    }
}

.user-detail {
    --badge-bg-color: #c5e0fd;
    --copy-text-color: #d65078;
    max-width: 100vw;
    padding: 72px 40px 48px
}

.user-detail.center-children {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center
}

.user-detail.center-children .subtitle {
    font-size: 16px;
    line-height: 26px
}

.user-detail.center-children .back-button {
    color: var(--color-link);
    cursor: pointer;
    margin-top: 16px
}

.user-detail__header {
    align-items: center;
    display: flex;
    margin-top: 40px;
    overflow: hidden
}

.user-detail__header>:not(:last-child) {
    margin-right: 16px
}

.user-detail__header__badge {
    align-items: center;
    background-color: var(--badge-bg-color);
    border-radius: 50%;
    color: var(--color-link);
    display: flex;
    flex-shrink: 0;
    font-size: 24px;
    font-weight: 600;
    height: 60px;
    justify-content: center;
    text-align: center;
    text-transform: uppercase;
    width: 60px
}

.user-detail__header__info {
    display: flex;
    flex: 1 1;
    flex-direction: column;
    justify-content: center;
    overflow: hidden
}

.user-detail__header__info>:not(:last-child) {
    margin-bottom: 6px
}

.user-detail__header__title {
    align-items: center;
    display: flex;
    font-size: 18px;
    font-weight: 600;
    line-height: 28px
}

.user-detail__header__title span {
    max-width: 100%;
    overflow-x: hidden;
    text-overflow: ellipsis;
    white-space: nowrap
}

.user-detail__header__user-id {
    align-items: center;
    display: flex;
    font-size: 14px
}

.user-detail__header__user-id>:not(:last-child) {
    margin-right: 8px
}

.user-detail__header__user-id span {
    white-space: nowrap
}

.user-detail__header__user-id__text {
    background-color: var(--color-white);
    border: 1px solid var(--color-border);
    color: var(--copy-text-color);
    display: inline-flex;
    font-family: Source Code Pro;
    font-size: 14px;
    font-weight: 500;
    max-width: 290px;
    overflow: hidden
}

.user-detail__header__action {
    align-items: center;
    display: flex
}

.user-detail .panel {
    margin-top: 40px
}

.user-detail__info-grid__grid {
    grid-gap: 40px 60px;
    display: grid;
    grid-template-columns: repeat(auto-fill,minmax(200px,1fr))
}

.user-detail__info-grid__item {
    display: flex;
    flex-wrap: wrap;
    font-size: 14px
}

.user-detail__info-grid__item__label {
    align-items: center;
    color: var(--color-secondary-text);
    display: inline-flex;
    margin-bottom: 12px;
    width: 100%
}

.user-detail__info-grid__item__body {
    max-width: 100%;
    overflow-x: hidden;
    overflow: visible;
    text-overflow: ellipsis;
    white-space: nowrap;
    white-space: normal;
    width: 100%
}

.user-detail__info-grid__item__guide {
    align-items: center;
    border: 1px solid var(--color-black);
    border-radius: 50%;
    cursor: pointer;
    display: inline-flex;
    height: 1em;
    justify-content: center;
    margin-left: 6px;
    width: 1em
}

.user-detail__info-grid__item__guide img {
    height: 1em
}

.user-detail__info-grid__item .input-field-container {
    margin: 0
}

.user-detail__info-grid__item .email-verified-button {
    font-weight: 400;
    margin-left: 12px
}

.user-detail__info-grid .phone-input,.user-detail__info-grid input {
    background-color: var(--color-window-bg)
}

.user-detail__info-grid .input-field-error {
    font-weight: 400
}

.user-detail__provider-box {
    align-items: center;
    background-color: var(--color-copy-box-bg);
    color: var(--color-copy-box);
    display: inline-flex;
    font-size: 13px;
    width: 100%
}

.user-detail__provider-box>:not(:last-child) {
    margin-right: 6px
}

.user-detail__provider-box.block-snippet {
    border: none;
    padding-right: 4px
}

.user-detail__provider-box>span>img {
    height: 1em
}

.user-detail__provider-box__user-id {
    flex: 1 1;
    overflow-x: hidden;
    padding: 4px 4px 4px 0
}

.user-detail__provider-box__user-id .copy-text {
    width: 100%
}

@media only screen and (min-width: 909px) {
    .user-detail {
        margin:0 auto;
        padding-left: 0;
        padding-right: 0;
        width: 829px
    }
}

.user-detail-title {
    color: var(--color-black);
    font-size: 28px;
    font-weight: 500;
    line-height: 34px;
    margin-bottom: 16px
}

.user-detail-subtitle {
    color: var(--color-secondary-text);
    margin-bottom: 48px
}

.user-detail-paper {
    background-color: var(--color-white);
    border-radius: 6px;
    box-shadow: 0 0 6px rgba(0,0,0,.16);
    display: block;
    max-width: 100%;
    width: 100%
}

.user-detail .block-info-connection {
    margin-bottom: 24px
}

.pill {
    margin-top: 4px
}

.copy-text {
    align-items: center;
    display: inline-flex;
    max-width: 100%
}

.copy-text .copy-text-text {
    flex: 1 1;
    max-width: 100%;
    overflow-x: hidden;
    padding-right: 4px;
    text-overflow: ellipsis;
    white-space: nowrap
}

.copy-text .copy-text-action {
    border-radius: 50%;
    cursor: pointer;
    transition: .3s
}

.copy-text .copy-text-action:hover {
    background-color: var(--color-copy-box-shadow);
    box-shadow: 0 0 0 4px var(--color-copy-box-shadow)
}

.copy-text .copy-text-notification {
    font-size: 12px;
    position: fixed;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    z-index: 1
}

.copy-text .copy-text-notification span {
    background-color: var(--color-black);
    border-color: var(--color-black);
    color: var(--color-white);
    margin: 0 12px;
    white-space: nowrap
}

.panel.no-padding-horizontal {
    padding-left: 0!important;
    padding-right: 0!important
}

.content-container {
    padding-bottom: 24px;
    padding-top: 24px;
    width: 100%
}

.content-container .header {
    align-items: center;
    color: var(--color-secondary-text);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-left: 34px;
    padding-right: 34px
}

@media only screen and (max-width: 600px) {
    .content-container .header {
        align-items:flex-start;
        flex-direction: column
    }
}

.content-container .header .header-primary {
    align-items: center;
    display: flex;
    flex-direction: row;
    font-size: 14px;
    font-weight: 500
}

@media only screen and (max-width: 600px) {
    .content-container .header .header-primary {
        align-items:flex-start;
        flex-direction: column
    }
}

.content-container .header .header-secondary {
    font-size: 12px;
    font-weight: 400;
    margin-left: 4px
}

@media only screen and (max-width: 600px) {
    .content-container .header .header-secondary {
        margin-left:0;
        margin-top: 2px
    }
}

.content-container .header .button-error {
    font-weight: 500
}

@media only screen and (max-width: 600px) {
    .content-container .header .button-error {
        margin-top:12px
    }
}

.content-container .table-container {
    display: block;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    margin-top: 18px;
    max-width: 100%;
    overflow-x: scroll;
    width: 100%
}

.content-container .table {
    border-collapse: collapse;
    border-spacing: 0;
    display: table;
    max-width: 100%;
    width: 100%
}

.content-container .table .thead {
    color: var(--color-black);
    font-size: 12px;
    line-height: 14px;
    text-transform: uppercase
}

.content-container .table .thead .head-row {
    background: #fafafa;
    border-bottom: 1px solid var(--color-border);
    border-top: 1px solid var(--color-border);
    padding: 15px 34px
}

.content-container .table .thead .head-row th {
    font-weight: 500;
    padding: 15px 35px;
    text-align: center;
    white-space: nowrap
}

.content-container .table .thead .head-row th:last-child {
    padding-right: 34px
}

.content-container .table .thead .head-row .w30 {
    padding-left: 34px;
    text-align: left
}

.content-container .table tbody {
    padding-left: 34px;
    padding-right: 34px
}

.content-container .table tbody .placeholder {
    display: flex
}

.content-container .table tbody .session-info {
    display: flex;
    margin-left: 34px;
    margin-right: 34px;
    width: 100%
}

.content-container .table tbody .session-row {
    margin-left: 34px;
    margin-right: 34px;
    margin-top: 29px
}

.content-container .table tbody .session-row.empty-row {
    display: flex
}

.content-container .table tbody .session-row.with-data td {
    color: var(--color-secondary-text);
    font-size: 14px;
    font-weight: 400;
    height: 1px;
    min-height: 28px;
    padding-left: 35px;
    padding-right: 35px;
    padding-top: 29px;
    text-align: center;
    white-space: nowrap;
    width: 23.3333333333%
}

.content-container .table tbody .session-row.with-data td span {
    background-color: var(--color-copy-box-bg);
    border-radius: 4px;
    display: flex;
    padding: 7px 8px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content
}

.content-container .table tbody .session-row.with-data td .button-error-outline {
    display: block
}

.content-container .table tbody .session-row.with-data td.session-id {
    font-family: Source Code Pro;
    font-weight: 500
}

.content-container .table tbody .session-row.with-data td:last-child {
    padding-right: 34px
}

.content-container .table tbody .session-row.with-data .w30 {
    padding-left: 34px;
    text-align: left
}

pre code.hljs {
    display: block;
    overflow-x: auto;
    padding: 1em
}

code.hljs {
    padding: 3px 5px
}

/*!
  Theme: An Old Hope – Star Wars Syntax
  Author: (c) Gustavo Costa <gusbemacbe@gmail.com>
  Maintainer: @gusbemacbe

  Original theme - Ocean Dark Theme – by https://github.com/gavsiu
  Based on Jesse Leite's Atom syntax theme 'An Old Hope'
    https://github.com/JesseLeite/an-old-hope-syntax-atom
*/
.hljs {
    background: #1c1d21;
    color: #c0c5ce
}

.hljs-comment,.hljs-quote {
    color: #b6b18b
}

.hljs-deletion,.hljs-name,.hljs-regexp,.hljs-selector-class,.hljs-selector-id,.hljs-tag,.hljs-template-variable,.hljs-variable {
    color: #eb3c54
}

.hljs-built_in,.hljs-link,.hljs-literal,.hljs-meta,.hljs-number,.hljs-params,.hljs-type {
    color: #e7ce56
}

.hljs-attribute {
    color: #ee7c2b
}

.hljs-addition,.hljs-bullet,.hljs-string,.hljs-symbol {
    color: #4fb4d7
}

.hljs-section,.hljs-title {
    color: #78bb65
}

.hljs-keyword,.hljs-selector-tag {
    color: #b45ea4
}

.hljs-emphasis {
    font-style: italic
}

.hljs-strong {
    font-weight: 700
}

.button-root {
    cursor: pointer
}

.padding-vertical-24 {
    padding-bottom: 24px!important;
    padding-top: 24px!important
}

.metadata-header {
    border-bottom: 1px solid var(--color-border);
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-bottom: 24px;
    width: 100%
}

.metadata-header .title {
    color: var(--color-secondary-text);
    font-weight: 500
}

.metadata-header .metadata-actions {
    display: flex;
    flex-direction: row
}

.metadata-header .metadata-actions>:not(:last-child) {
    margin-right: 16px
}

.metadata-content-container {
    background: #0d2e4e;
    border-radius: 6px;
    margin-top: 22px;
    overflow-x: auto;
    padding: 15px 28px;
    white-space: pre
}

.metadata-content-container .hljs {
    background: #0d2e4e;
    font-size: 13px;
    font-weight: 400;
    line-height: 21px;
    padding: 0!important
}

.metadata-edit-box {
    background-color: var(--color-window-bg);
    border: 1px solid #e0e0e0;
    border-radius: 6px;
    margin-top: 24px;
    max-width: 100%;
    min-height: 200px;
    min-width: 100%;
    padding: 4px
}

.users-list {
    max-width: 100vw;
    padding: 48px 40px 
}

.users-list-title {
    color: var(--color-black);
    font-size: 28px;
    font-weight: 500;
    line-height: 34px;
    margin-bottom: 16px
}

.users-list-title .pill.paid-feature-badge {
    background-color: var(--color-badge-bg);
    border-radius: 6px;
    color: var(--color-badge);
    display: inline-block;
    font-size: 14px;
    line-height: 1.1;
    margin-top: 0;
    vertical-align: middle
}

.users-list-subtitle {
    color: var(--color-secondary-text);
    margin-bottom: 48px
}

.users-list-paper {
    background-color: var(--color-white);
    border-radius: 6px;
    box-shadow: 0 0 6px rgba(0,0,0,.16);
    display: block;
    max-width: 100%;
    width: 100%
}

.users-list .block-info-connection {
    margin-bottom: 24px
}

.tenant-list-dropdown {
    background: #fff;
    border: 1px solid #e5e5e5;
    border-radius: 6px;
    color: #222;
    font-size: 14px;
    min-width: 200px;
    padding: 9px 13px
}

.tenant-id-container {
    align-items: center;
    display: flex;
    margin-bottom: 16px
}

.tenant-id-title {
    font-size: 16px;
    font-weight: 500;
    margin-right: 8px
}

@media only screen and (min-width: 909px) {
    .users-list {
        margin:0 auto;
        padding-left: 0;
        padding-right: 0;
        width: 829px
    }
}

button#sign-out-btn {
    background: var(--color-white);
    border: 1px solid var(--color-black);
    border-radius: 6px;
    cursor: pointer;
    font-family: inherit;
    font-size: 14px;
    font-weight: 500;
    line-height: 17px;
    padding: 6px 8px;
    position: absolute;
    right: 60px;
    top: 24px;
    z-index: 1
}

.auth-container {
    align-items: center;
    background-image: var(--auth-background-portrait);
    background-position: top;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    flex-flow: column;
    height: 100vh;
    justify-content: center;
    width: 100vw
}

@media only screen and (min-width: 600px) {
    .auth-container {
        background-image:var(--auth-background)
    }
}

.auth-container .block-container {
    margin: 32px auto;
    max-width: 320px
}

.auth-container .block-container.forgot-password,.auth-container .block-container.sign-up {
    max-width: 460px
}

@media only screen and (min-width: 600px) {
    .auth-container .block-container {
        max-width:450px
    }

    .auth-container .block-container.forgot-password,.auth-container .block-container.sign-up {
        max-width: 760px;
        width: max(600px,41vw)
    }
}

.auth-container .text-title {
    margin-bottom: 8px
}

.auth-container .text-label {
    margin-bottom: 16px
}

.auth-container label {
    -webkit-margin-after: 7px;
    display: inline-block;
    font-size: 14px;
    font-weight: 500;
    margin-block-end:7px}

.block-container hr {
    border-bottom: none;
    border-top: 1px solid #ddd;
    margin-block:24px}

.block-container .link {
    color: var(--color-link);
    cursor: pointer
}

.block-container .link:hover {
    text-decoration: underline
}

form .input-field-inset:not(.input-field-inset-focused) input {
    background-color: var(--color-input-unfocused)
}

.error-response-container {
    font-size: 14px
}

.cta-container {
    align-items: center;
    display: flex;
    justify-content: space-between
}

.cta-container .secondary-cta-btn {
    font-family: inherit;
    font-size: 14px
}

.cta-container .secondary-cta-btn.forgot-btn {
    text-decoration: underline
}

.cta-container img.back-chevron {
    -webkit-margin-end: 6px;
    margin-inline-end:6px}

.command-container {
    border: 1px solid var(--color-border-command);
    border-radius: 6px;
    display: flex;
    margin-block:24px 18px;padding-block:6px;padding-inline:8px 4px;white-space: break-spaces
}

.command-container>:not(:last-child) {
    margin-right: 12px
}

.command-container .clipboard-btn-container {
    border-radius: 50%;
    cursor: pointer;
    height: 24px;
    min-width: 24px;
    transition: all .1s ease-in-out
}

.command-container .clipboard-btn-container:hover {
    background: hsla(0,0%,85%,.6)
}

.command-container .clipboard-btn-container .clipboard-icon {
    height: 14px;
    width: 12px
}

.command-container code.command {
    color: var(--color-command);
    flex: 1 1;
    font-size: 13px;
    line-height: 1.6;
    max-height: 160px;
    overflow-y: scroll;
    word-break: break-word
}

.command-container code.command .hljs-string {
    color: inherit
}

.command-container .tooltip-container {
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content
}

.command-container .tooltip-container .tooltip-container__popup {
    padding: 3px 8px
}

.error-container {
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: center;
    width: 100%
}

.error-container .block-container {
    margin: 32px auto;
    max-width: 560px;
    text-align: center
}

.error-container .block-container .text-title {
    font-size: 24px;
    line-height: normal;
    margin-top: 8px
}

.error-container .block-container .title-image {
    margin-left: auto;
    margin-right: auto
}

.error-container .block-container p {
    letter-spacing: .14px;
    margin-top: 12px
}

.notification {
    -webkit-animation: notificationScale .3s;
    animation: notificationScale .3s;
    background-color: var(--color-window-bg);
    border: 1px solid var(--color-border);
    border-radius: 6px;
    box-shadow: 0 0 6px var(--color-shadow);
    display: flex;
    font-size: 14px;
    line-height: 24px;
    padding: 16px;
    transition: .3s
}

.notification>:not(:last-child) {
    margin-right: 8px
}

.notification__icon {
    align-items: center;
    display: flex
}

.notification__icon img {
    height: 16px
}

.notification__info {
    flex: 1 1;
    font-weight: 500;
    max-width: 300px;
    padding-right: 24px;
    white-space: wrap
}

.notification__close {
    align-items: center;
    display: flex
}

.notification__close svg {
    cursor: pointer;
    width: 8px
}

.notification.notification-info {
    background-color: var(--color-info-bg);
    border-color: var(--color-border-info);
    box-shadow: 0 0 6px var(--color-info-shadow);
    color: var(--color-info)
}

.notification.notification-info svg {
    stroke: var(--color-info)
}

.notification.notification-error {
    background-color: var(--color-error-bg);
    border-color: var(--color-border-error);
    box-shadow: 0 0 6px var(--color-error-shadow);
    color: var(--color-error)
}

.notification.notification-error svg {
    stroke: var(--color-error)
}

.notification.notification-success {
    background-color: var(--color-success-bg);
    border-color: var(--color-border-success);
    box-shadow: 0 0 6px var(--color-success-shadow);
    color: var(--color-success)
}

.notification.notification-success svg {
    stroke: var(--color-success)
}

.notification-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    max-height: 100vh;
    padding: 0 20px;
    position: fixed;
    right: 0;
    top: 78px;
    transition: .3s;
    width: -webkit-max-content;
    width: max-content
}

.notification-container>:not(:last-child) {
    margin-bottom: 16px
}

@-webkit-keyframes notificationScale {
    0% {
        -webkit-transform: scale(0);
        transform: scale(0)
    }

    to {
        -webkit-transform: scale(1);
        transform: scale(1)
    }
}

@keyframes notificationScale {
    0% {
        -webkit-transform: scale(0);
        transform: scale(0)
    }

    to {
        -webkit-transform: scale(1);
        transform: scale(1)
    }
}

body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: var(--color-black);
    font-family: Rubik,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif
}

code {
    font-family: Menlo,Source Code Pro,Monaco,Consolas,Courier New,monospace
}

* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    /* position: relative */
}

#root {
    background-color: var(--color-window-bg);
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    min-width: 100%
}

#root>* {
    flex: 1 1
}

#root>.footer {
    flex: 0 1
}

a[href],button:not(:disabled) {
    cursor: pointer
}

button:disabled {
    opacity: .5;
    pointer-events: none
}

body {
    --color-primary: #f93;
    --color-primary-opacity-40: rgba(255,153,51,.4);
    --color-primary-darker: #ff8000;
    --color-primary-darkest: #ff4000;
    --color-white: #fff;
    --color-black: #222;
    --color-window-bg: #f8f8f8;
    --color-loader-placeholder-bg: hsla(33,4%,41%,.4);
    --color-shadow: rgba(0,0,0,.16);
    --color-container-shadow: rgba(0,0,0,.16);
    --color-error-shadow: rgba(158,37,38,.16);
    --color-error-bg: #fdf0f1;
    --color-info-shadow: rgba(158,37,38,.16);
    --color-info-bg: #ebf5ff;
    --color-success-bg: #f1faf7;
    --color-success-shadow: rgba(4,84,62,.16);
    --color-link: #007aff;
    --color-input-unfocused: #fafafa;
    --color-badge-bg: #fff2ca;
    --color-border: #e5e5e5;
    --color-border-error: #ef7977;
    --color-border-info: #97bdfa;
    --color-border-success: #49c899;
    --color-border-command: #ddd;
    --color-secondary-text: #6e6a65;
    --color-error: #9e2526;
    --color-info: #1f5adb;
    --color-success: #04543e;
    --color-command: #d65078;
    --color-badge: #dc8d0d;
    --color-emailpassword-bg: #ddfcf7;
    --color-emailpassword-text: #006a5b;
    --color-passwordless-bg: #ffeaf7;
    --color-passwordless-text: #a8115a;
    --color-google-bg: #f1deff;
    --color-google-text: #5c3f79;
    --color-github-bg: #deffee;
    --color-github-text: #096c38;
    --color-facebook-bg: #e3ebff;
    --color-facebook-text: #154bdd;
    --color-apple-bg: #e5edff;
    --color-apple-text: #0b1c45;
    --color-custom-provider-bg: #e4e0ff;
    --color-custom-provider-text: #5425b0;
    --color-copy-box: #536579;
    --color-copy-box-bg: #f0f4f7;
    --color-copy-box-shadow: rgba(83,101,121,.2);
    --color-button-error: #ed344e;
    --color-button-error-border: #de233d;
    --color-button-error-disabled: #ddd;
    --color-button-error-hover: #de233d;
    --z-index-inline-popup: 1;
    --z-index-modal-popup: 10;
    --color-popup-item-hover: #f0f0f0;
    --color-popup-item-delete-hover: rgba(222,35,61,.12);
    --color-button-outline-hover: #fafafa
}

@supports (color: rgb(0 0 0/0)) {
    body {
        --color-link:#0076ff
    }
}

[data-theme=dark] {
    --color-black: #fff;
    --color-white: #222;
    --color-window-bg: #4a4a4a;
    --color-secondary-text: #ddd
}

.full-width,.panel {
    width: 100%
}

.panel {
    background-color: var(--color-white);
    border-radius: 12px;
    box-shadow: 0 0 6px var(--color-shadow);
    display: block;
    max-width: 100%;
    padding: 0 40px
}

.panel__header {
    align-items: center;
    display: flex;
    font-size: 14px;
    justify-content: space-between;
    min-height: 64px;
    padding: 16px 0
}

.panel__header .title {
    color: var(--color-secondary-text);
    flex: 1 1;
    font-size: 14px;
    font-weight: 600;
    text-transform: uppercase
}

.panel__header .actions {
    display: inline-flex
}

.panel__header .actions>:not(:last-child) {
    margin-left: 16px
}

.panel__header.with-border {
    border-bottom: 1px solid var(--color-border)
}

.panel__body {
    padding: 24px 0 18px
}

.layout-modal {
    background: none;
    display: block;
    height: 100vw;
    left: 0;
    pointer-events: none;
    position: fixed;
    top: 0;
    width: 100vw;
    z-index: var(--z-index-modal-popup)
}

.layout-modal__backdrop {
    background-color: var(--color-black);
    display: block;
    height: 100%;
    opacity: .4;
    pointer-events: all;
    width: 100%
}

.layout-modal__container {
    display: flex;
    height: 100%;
    position: relative;
    width: 100%
}

.layout-modal__close {
    align-items: flex-start;
    display: flex;
    height: 32px;
    justify-content: flex-end
}

.layout-modal__close>div {
    align-items: center;
    background-color: var(--color-border);
    border-radius: 50%;
    cursor: pointer;
    display: flex;
    font-size: 22px;
    height: 1em;
    justify-content: center;
    padding: 6px;
    width: 1em
}

.layout-modal__close>div>img {
    width: 8px
}

.layout-modal .panel {
    left: 50vw;
    margin: 0 auto;
    max-height: calc(100vh - 32px);
    max-width: calc(100vh - 32px);
    padding-bottom: 16px;
    padding-top: 16px;
    pointer-events: all;
    position: absolute;
    top: 50vh;
    -webkit-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
    width: 470px;
    z-index: 1
}

.layout-modal .panel__header {
    align-items: flex-end
}

.layout-modal-trigger {
    cursor: pointer;
    display: inline-block
}

.block-container {
    background-color: var(--color-white);
    border-radius: 18pt;
    box-shadow: 1px 1px 6px var(--color-container-shadow);
    display: flex;
    flex-flow: column;
    padding: 32px 18px;
    width: calc(100% - 32px)
}

@media only screen and (min-width: 992px) {
    .block-container {
        padding:32px 48px
    }
}

.block-snippet-small {
    border: 1px solid var(--color-border);
    border-radius: 2px;
    padding: 2px 4px
}

.block-snippet {
    padding: 3px 8px
}

.block-snippet,.block-snippet-large {
    border: 1px solid var(--color-border);
    border-radius: 4px
}

.block-snippet-large {
    padding: 6px 8px
}

.block-small {
    border-radius: 6px;
    padding: 8px 12px
}

.block-medium {
    border-radius: 6pt;
    border-style: solid;
    border-width: 1px;
    box-shadow: 0 0 6px var(--color-shadow);
    margin: 4px 0;
    padding: 20px 16px
}

.block-medium p {
    line-height: 23px
}

.block-medium p:not(:last-child) {
    margin-bottom: 16px
}

@media only screen and (min-width: 600px) {
    .block-medium p:not(:last-child) {
        margin-bottom:6px
    }
}

.block-large {
    border-radius: 24px;
    box-shadow: 1px 1px 60px var(--color-container-shadow);
    padding: 32px
}

.block-large p {
    letter-spacing: .14px;
    line-height: 21px
}

@media only screen and (min-width: 768px) {
    .block-large {
        padding:32px 48px
    }
}

.block-error {
    background-color: var(--color-error-bg);
    border-color: var(--color-border-error);
    color: var(--color-error)
}

.block-info {
    background-color: var(--color-info-bg);
    border-color: var(--color-border-info);
    color: var(--color-info)
}

.button:not(.flat) {
    align-items: center;
    background-color: var(--color-primary);
    border: 1px solid var(--color-primary-darker);
    border-radius: 6px;
    box-shadow: 0 0 5px 0 rgba(0,0,0,.15);
    color: var(--color-white);
    cursor: pointer;
    display: flex;
    font-family: inherit;
    font-size: 14px;
    font-weight: 600;
    line-height: 16px;
    padding: 8px 16px
}

.button:not(.flat)>:not(:last-child) {
    margin-right: .75em
}

.button:not(.flat).small {
    font-weight: 400;
    padding: 6px 8px
}

.button.flat,button.flat {
    background: none;
    border: none;
    color: var(--color-secondary-text);
    display: inline;
    font-size: inherit;
    font-weight: 600;
    margin: 0;
    padding: 0
}

.button.flat>:not(:last-child),button.flat>:not(:last-child) {
    margin-right: 8px
}

.button.button-error,button.button-error {
    background: var(--color-button-error);
    border: none;
    color: var(--color-white);
    display: inline;
    font-size: inherit;
    margin: 0;
    transition: background-color .3s
}

.button.button-error>:not(:last-child),button.button-error>:not(:last-child) {
    margin-right: 8px
}

.button.button-error:disabled,button.button-error:disabled {
    background-color: var(--color-button-error-disabled)
}

.button.button-error:hover,button.button-error:hover {
    background-color: var(--color-button-error-hover)
}

.button.button-error-outline,button.button-error-outline {
    background-color: transparent;
    border-color: var(--color-button-error);
    border-style: solid;
    border-width: 1;
    box-shadow: none;
    color: var(--color-button-error);
    display: inline;
    font-size: inherit;
    margin: 0;
    transition: background-color .3s
}

.button.button-error-outline>:not(:last-child),button.button-error-outline>:not(:last-child) {
    margin-right: 8px
}

.button.button-error-outline:disabled,button.button-error-outline:disabled {
    border-color: var(--color-button-error-disabled)
}

.button.button-error-outline:hover,button.button-error-outline:hover {
    background-color: var(--color-button-outline-hover)
}

.button.outline,button.outline {
    background: none;
    border-color: var(--color-secondary-text);
    box-shadow: none;
    color: var(--color-secondary-text);
    font-weight: 500!important;
    transition: background-color .3s
}

.button.outline:hover,button.outline:hover {
    background-color: var(--color-button-outline-hover);
    box-shadow: inherit
}

.button.link,a,button.link {
    color: var(--color-link)
}

.button.link:not(.flat),a:not(.flat),button.link:not(.flat) {
    border-color: var(--color-link)
}

.button.link:not(.flat):hover,a:not(.flat):hover,button.link:not(.flat):hover {
    background-color: var(--color-button-outline-hover)
}

.footer a:hover {
    background-color: transparent
}

.text-title {
    font-size: 24px;
    font-weight: 600;
    letter-spacing: .24px;
    line-height: 40px
}

.text-small {
    font-size: 14px;
    line-height: 16px
}

.text-medium {
    font-size: 16px;
    line-height: 16px
}

.text-large {
    font-size: 18px;
    line-height: 16px
}

.text-bold {
    font-weight: 600
}

.text-error {
    color: var(--color-error)
}

.text-info {
    color: var(--color-info)
}

.text-black {
    color: var(--color-black)
}

.text-label {
    color: var(--color-secondary-text)
}

.title-image {
    height: 32px;
    margin-bottom: 16px;
    width: 35px
}

.title-image-smaller {
    height: 29px;
    margin-bottom: 16px;
    width: 32px
}

.with-footer {
    margin-bottom: 100px
}

.tooltip-container {
    cursor: default;
    display: inline-flex
}

.tooltip-container__popup {
    background-color: var(--color-white);
    border-radius: 6px;
    color: var(--color-black);
    font-size: 14px;
    line-height: 23px;
    padding: 14px;
    position: fixed;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    z-index: var(--z-index-inline-popup)
}

.tooltip-container__popup:before {
    border: 6px solid transparent;
    content: "";
    display: block;
    height: 0;
    position: absolute;
    width: 0
}

.tooltip-container__popup p:not(:last-child) {
    margin-bottom: 6px
}

.tooltip-container__popup .block-snippet-small {
    background-color: var(--color-window-bg);
    color: var(--color-secondary-text)
}

.tooltip-container__popup.popup_left:before {
    border-left-color: var(--color-white);
    border-right: none;
    left: 100%;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%)
}

.tooltip-container__popup.popup_right:before {
    border-left: none;
    border-right-color: var(--color-white);
    left: 0;
    top: 50%;
    -webkit-transform: translateX(-100%) translateY(-50%);
    transform: translateX(-100%) translateY(-50%)
}

.tooltip-container__popup.popup_top:before {
    border-bottom: none;
    border-top-color: var(--color-white);
    left: 50%;
    top: 100%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%)
}

.tooltip-container__popup.popup_bottom:before {
    border-bottom-color: var(--color-white);
    border-top: none;
    left: 50%;
    top: 0;
    -webkit-transform: translateY(-100%) translateX(-50%);
    transform: translateY(-100%) translateX(-50%)
}

.center {
    text-align: center
}

.flex-center-x {
    display: flex;
    justify-content: center
}

.flex-center-y {
    align-items: center;
    display: flex
}

.with-thin-scrollbar::-webkit-scrollbar {
    height: 5px;
    width: 5px
}

.with-thin-scrollbar::-webkit-scrollbar-thumb {
    box-shadow: inset 0 0 6px var(--color-loader-placeholder-bg)
}

.bold-400 {
    font-weight: 400!important
}

.bold-500 {
    font-weight: 500!important
}

.bold-600 {
    font-weight: 600!important
}

.bold-700 {
    font-weight: 700!important
}

.add-user-button{
        background: var(--color-white);
        border: 1px solid var(--color-black);
        border-radius: 6px;
        cursor: pointer;
        display: inline-block;
        font-family: inherit;
        font-size: 14px;
        font-weight: 500;
        height: 35px;
        line-height: 17px;
        margin-bottom: 10px;
        padding: 6px 8px;
        

}

@media only screen and (max-width: 650px){

.users-list-table tr th:nth-child(2),
.users-list-table tr td:nth-child(2){
    display: none;
}
}

@media only screen and (max-width: 530px){

.users-list-table tr th:nth-child(3),
.users-list-table tr td:nth-child(3),
.users-list-table tr th:nth-child(2),
.users-list-table tr td:nth-child(2){
    display: none;
}
}