.video-page-wrepper{
    max-width: 100vw;
    /* height: calc(100vh - 153px); */
    margin: 48px 40px;
    transition: margin 0.3s ease;
}

.video-page{
    margin: 0 auto;
    max-width: 960px;
    /* height: 100%; */
    padding: 20px;
    background-color: white;
    border-radius: 10px;
    transition: border 0.3s ease;
}
.page-header{
margin-right: 0.5em;
font-size: 20px;
line-height: 20px;
font-weight: 500;
}
hr{
    border:1px solid lightgray;
    margin-block: 0.5em;
}
.page-container{
display: flex;
flex-direction: column;
align-items: center;
}

.file-upload-wrapper{
    margin-block-start: 70px;
    margin-block-end: 20px;

}
button.upload-button{
    background: #065fd4 ;
    color: #fff;
    border: 0px ;
    border-radius: 6px;
    cursor: pointer;
    display: inline-block;
    font-family: inherit;
    font-size: 14px;
    font-weight: 500;
    height: 35px;
    line-height: 17px;
    margin-bottom: 10px;
    padding: 6px 8px;
}

@media (max-width:530px) {

    .video-page-wrepper{
        margin: 10px 0;
    }
    .video-page{
        border-radius: 0;
    }
}